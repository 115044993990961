export const TITLES = [
  { name: '先生', value: 1 },
  { name: '太太', value: 3 },
  { name: '小姐', value: 2 }
];

export const LEVEL_TYPES = [
  { name: '級', value: 1 },
  { name: '層', value: 2 },
];

export const MOVING_ITEMS = [
  { id: 1, name: "雙人床", type: 1 },
  { id: 2, name: "單人床", type: 1 },
  { id: 3, name: "子母床", type: 1 },
  { id: 4, name: "梳化床", type: 1 },
  { id: 5, name: "碌架床", type: 1 },
  { id: 6, name: "組合床", type: 1 },
  { id: 7, name: "BB床", type: 1 },
  { id: 8, name: "床褥", type: 1 },
  { id: 9, name: "梳化", type: 1 },
  { id: 10, name: "茶几", type: 1 },
  { id: 11, name: "書架", type: 1 },
  { id: 12, name: "組合櫃", type: 1 },
  { id: 13, name: "電視櫃", type: 1 },
  { id: 14, name: "床頭櫃", type: 1 },
  { id: 15, name: "地櫃", type: 1 },
  { id: 16, name: "衣櫃", type: 1 },
  { id: 17, name: "矮櫃", type: 1 },
  { id: 18, name: "高櫃", type: 1 },
  { id: 19, name: "書櫃", type: 1 },
  { id: 20, name: "鞋櫃", type: 1 },
  { id: 21, name: "酒櫃", type: 1 },
  { id: 22, name: "玻璃飾櫃", type: 1 },
  { id: 23, name: "文件櫃", type: 1 },
  { id: 24, name: "小露寶", type: 1 },
  { id: 25, name: "書枱", type: 1 },
  { id: 26, name: "摺枱", type: 1 },
  { id: 27, name: "神枱", type: 1 },
  { id: 28, name: "餐枱", type: 1 },
  { id: 29, name: "木餐枱", type: 1 },
  { id: 30, name: "玻璃枱", type: 1 },
  { id: 31, name: "梳妝枱", type: 1 },
  { id: 32, name: "雲石枱", type: 1 },
  { id: 33, name: "麻雀枱", type: 1 },
  { id: 34, name: "電腦枱", type: 1 },
  { id: 35, name: "辦公枱", type: 1 },
  { id: 36, name: "L形枱", type: 1 },
  { id: 37, name: "會議枱", type: 1 },
  { id: 38, name: "電視", type: 1 },
  { id: 39, name: "雪櫃", type: 1 },
  { id: 40, name: "洗衣機", type: 1 },
  { id: 41, name: "乾衣機", type: 1 },
  { id: 42, name: "微波爐", type: 1 },
  { id: 43, name: "抽濕機", type: 1 },
  { id: 44, name: "電暖爐", type: 1 },
  { id: 45, name: "煮食爐", type: 1 },
  { id: 46, name: "抽油煙機", type: 1 },
  { id: 47, name: "冷氣機", type: 1 },
  { id: 48, name: "碎紙機", type: 1 },
  { id: 49, name: "清新機", type: 1 },
  { id: 50, name: "電腦主機", type: 1 },
  { id: 51, name: "電腦螢幕", type: 1 },
  { id: 52, name: "影印機", type: 1 },
  { id: 53, name: "夾萬", type: 1 },
  { id: 54, name: "屏風", type: 1 },
  { id: 55, name: "辦公椅", type: 1 },
  { id: 56, name: "大班椅", type: 1 },
  { id: 57, name: "按摩椅", type: 1 },
  { id: 58, name: "木椅", type: 1 },
  { id: 59, name: "摺椅", type: 1 },
  { id: 60, name: "皮椅", type: 1 },
  { id: 61, name: "鏡子", type: 1 },
  { id: 62, name: "玻璃", type: 1 },
  { id: 63, name: "字畫", type: 1 },
  { id: 64, name: "座地風扇", type: 1 },
  { id: 65, name: "蒸餾水機", type: 1 },
  { id: 66, name: "健身器材", type: 1 },
  { id: 67, name: "音響組合", type: 1 },
  { id: 68, name: "鋼琴", type: 1 },
  { id: 69, name: "電子琴", type: 1 },
  { id: 70, name: "結他", type: 1 },
  { id: 71, name: "紙箱", type: 1 },
  { id: 72, name: "膠箱", type: 1 },
  { id: 73, name: "紅白藍袋", type: 1 },
  { id: 74, name: "四桶膠箱", type: 1 },
  { id: 75, name: "行李箱", type: 1 },
  { id: 76, name: "魚缸", type: 1 },
  { id: 77, name: "盆栽植物", type: 1 }
];
