import React, { useEffect, useState } from 'react';
import { PrimeReactProvider } from 'primereact/api';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LayoutDashboard from 'layouts/LayoutDashboard';
import Home from 'pages/Home';
import Dashboard from 'pages/Dashboard';
import Quotation from 'pages/Quotation';
import Orderform from 'pages/Orderform';
import User from 'pages/User';
import NotFound from 'pages/NotFound';

// import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const App = () => {
  const [pageParams, setPageParams] = useState({});
  return (
    <PrimeReactProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/" element={<LayoutDashboard pageParams={pageParams} />}>
            <Route path="/dashboard" element={<Dashboard setPageParams={setPageParams} />} />
            <Route path="/quotation" element={<Quotation setPageParams={setPageParams} />} />
            <Route path="/quotation/:id" element={<Quotation setPageParams={setPageParams} />} />
            <Route path="/orderform" element={<Orderform setPageParams={setPageParams} />} />
            <Route path="/user" element={<User setPageParams={setPageParams} />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </PrimeReactProvider>
  );
};

export default App;
