import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';

const ItemNumberInput = (props) => {
  const [visible, setVisible ] = useState(props.visible || true);
  const [value, setValue ] = useState(props.value || 0);

  const updateValue = (type) => {
    if (type === 'plus') {
      setValue(value => value + 1);
      if (props.onChange) {
        props.onChange(value + 1);
      }
    } else if (type === 'minus') {
      if (value <= 0) {
        return false;
      }
      setValue(value => value - 1);
      if (props.onChange) {
        props.onChange(value - 1);
      }
    }
  };

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <div className={`item-number-container flex flex-row align-items-center rounded ${visible?'':'opacity-20'}`}>
      <div className="flex flex-row field-input w-6 mr-2">
        <Button icon="pi pi-plus" aria-label="Filter" severity="success" className="button add border-round-left-sm border-noround-right" onClick={() => {updateValue('plus')}} />
        <InputNumber value={value} className="" inputClassName="border-noround text-center" inputStyle={{ width: '30px' }} disabled />
        <Button icon="pi pi-minus" aria-label="Filter" severity="danger" className="button minus border-round-right-sm border-noround-left" onClick={() => {updateValue('minus')}} />
      </div>
      <div className={`field-name w-6 ${value>0?'text-900 font-bold':'text-500'}`}>{props.itemName}</div>
    </div>
  );
};

export default ItemNumberInput;