import React, { useEffect, useState, useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import MessageBox from 'components/MessageBox';

import fetchApi from 'lib/fetchApi';
import functions from 'lib/functions';

const USER_GROUPS = [
  { id: 1, name: '超級管理員' },
  { id: 2, name: '管理員' },
  { id: 3, name: '一般用戶' },
];
const DEFAULT_USER = {
  id: '',
  fullname: '',
  nickname: '',
  username: '',
  password: '',
  mobile: '',
  email: '',
  user_group_id: 3,
  user_group: USER_GROUPS[2],
};

const User = (props) => {
  const toast = useRef(null);
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userDetail, setUserDetail] = useState(DEFAULT_USER);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });

  useEffect(() => {
    props.setPageParams({
      alias: 'user',
      title: '用戶管理'
    });
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await fetchApi('api/v1/user/getUsers');
    if (response?.data?.users) {
      setUsers(response.data.users.map((user) => {
        return {
          ...user,
          user_group_name: user.user_group.group_name,
        };
      }));
    }
  };

  const startCreate = () => {
    setUserDetail(DEFAULT_USER);
    setIsEditing(false);
    setIsOpenDetail(true);
    setIsUnsaved(false);
    setIsSaving(false);
  };

  const startEdit = async (userId) => {
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsUnsaved(false);
    setIsSaving(false);
    const response = await fetchApi('api/v1/user/getUsers', {
      user_id: userId,
    });
    if (response?.data?.users) {
      let user = response.data.users[0];
      setUserDetail({
        ...user,
        password: '',
        user_group: USER_GROUPS.filter((group) => { return group.id === user.user_group_id; })[0]
      });
    }
    setIsLoadingDetail(false);
  };

  const updateUserDetail = (field, value) => {
    setIsUnsaved(true);
    if (field === 'user_group') {
      setUserDetail(userDetail => {
        return {
          ...userDetail,
          user_group_id: value.id,
          user_group: value,
        };
      });
    } else {
      setUserDetail(userDetail => {
        return {
          ...userDetail,
          [field]: value,
        };
      });
    }
  };

  const save = async () => {
    // validation
    var errors = [];
    if (userDetail.fullname.trim() === '') {
      errors.push('必須輸入姓名');
    }
    if (userDetail.username.trim() === '') {
      errors.push('必須輸入登入名稱');
    }
    if (!isEditing) {
      if (!functions.validateValue('password', userDetail.password)) {
        errors.push('密碼最少為 6 位字元');
      }
    }
    if (userDetail.email.trim() !== '') {
      if (!functions.validateValue('email', userDetail.email)) {
        errors.push('電郵格式不正確');
      }
    }
    if (errors.length > 0) {
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul>{errors.map((error, i) => { return <li key={i}>{error}</li>; })}</ul>,
        type: 'warning',    
      });
      return false;
    }
    // save
    setIsSaving(true);
    const response = await fetchApi('api/v1/user/saveUser', userDetail);
    if (response.state === 1) {
      toast.current.show({ severity: 'success', summary: '用戶管理', detail: isEditing?'已成功儲存用戶資料。':'已成功新增用戶。' });
      setIsUnsaved(false);
    } else {
      if (response.error[0] === 'duplicated_username') {
        setMessageBoxData({
          show: true,
          title: '資料錯誤',
          content: <ul><li>登入名稱「{userDetail.username}」已被使用</li></ul>,
          type: 'danger',    
        });
      } else {

      }
    }
    setIsSaving(false);
  };

  const closeDetail = () => {
    if (isUnsaved) {
      confirmDialog({
        message: '你還未儲存變更，確定要關閉？',
        header: '未儲存變更',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-warning',
        accept: () => {setIsOpenDetail(false)},
      });
    } else {
      setIsOpenDetail(false)
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="p-0 border-0 bg-white"
          start={
            <div>用戶列表</div>
          }
          end={
            <Button label="新增" onClick={() => {startCreate()}} icon="pi pi-plus" size="small" />
          }
        />
      </div>
      <DataTable
        value={users}
        selectionMode="single"
        onRowClick={(e) => {startEdit(e.data.id)}}
        emptyMessage="未有記錄"
        // loading={users===null}
        selection={selectedUser}
        stripedRows
        onSelectionChange={(e) => setSelectedUser(e.value)} dataKey="id">
        <Column field="fullname" header="全名"></Column>
        <Column field="nickname" header="暱稱"></Column>
        <Column field="user_group_name" header="用戶組"></Column>
      </DataTable>
      <Dialog
        className="detail-dialog"
        visible={isOpenDetail}
        closable={false}
        onHide={() => {setIsOpenDetail(false)}}
        header={isEditing ? userDetail.fullname : '新增用戶'}
        footer={
          <>
            <Button label="關閉" onClick={() => {closeDetail()}} outlined disabled={isSaving} />
            <Button label="儲存" onClick={() => {save()}} disabled={isLoadingDetail || isSaving} />
          </>
        }
      >
        {isLoadingDetail ?
          <div className="flex justify-content-center m-6">
            <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
          </div>
        :
          <>
            <div className="grid">
              <div className="col-6 flex flex-column gap-2">
                <label>姓名</label>
                <InputText value={userDetail.fullname} onChange={(e) => {updateUserDetail('fullname', e.target.value)}} />
              </div>
              <div className="col-6 flex flex-column gap-2">
                <label>暱稱</label>
                <InputText value={userDetail.nickname} onChange={(e) => {updateUserDetail('nickname', e.target.value)}} />
              </div>
              <div className="col-4 flex flex-column gap-2">
                <label>電話</label>
                <InputText value={userDetail.mobile} onChange={(e) => {updateUserDetail('mobile', e.target.value)}} />
              </div>
              <div className="col-8 flex flex-column gap-2">
                <label>電郵</label>
                <InputText value={userDetail.email} onChange={(e) => {updateUserDetail('email', e.target.value)}} />
              </div>
              <Divider />
              <div className="col-6 flex flex-column gap-2">
                <label>登入名稱</label>
                <InputText value={userDetail.username} disabled={isEditing} onChange={(e) => {updateUserDetail('username', e.target.value)}} />
                <small>{isEditing?'無法修改。':'用戶將使用此名稱登入系統。'}</small>
              </div>
              <div className="col-6 flex flex-column gap-2">
                <label>密碼</label>
                <InputText value={userDetail.password} onChange={(e) => {updateUserDetail('password', e.target.value)}} />
                <small>{isEditing?'如不修改密碼，請留空。':'用戶將使用此密碼登入系統。'}</small>
                <small></small>
              </div>
            </div>
            <div className="grid">
              <div className="col-6 flex flex-column gap-2">
                <label>用戶組</label>
                <Dropdown value={userDetail.user_group} onChange={(e) => updateUserDetail('user_group', e.value)} options={USER_GROUPS} optionLabel="name" placeholder="選取一項" className="w-full" />
              </div>
            </div>
          </>
        }
      </Dialog>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default User;
  