import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { Toast } from 'primereact/toast';
import SignaturePad from 'react-signature-pad-wrapper'

import ItemNumberInput from 'components/ItemNumberInput';

import fetchApi from 'lib/fetchApi';
import functions from 'lib/functions';
import { TITLES, LEVEL_TYPES, MOVING_ITEMS } from 'lib/data.js';

const BLANK_QUOTATION = {
  company_name: '',
  customer_name: '',
  contact_phone: '',
  title: 1,
  moving_date: null,
  moving_time: null,
  old_address: '',
  old_address_stairs: 0,
  old_address_level_type: 1,
  old_address_platform: 0,
  new_address: '',
  new_address_stairs: 0,
  new_address_level_type: 1,
  new_address_platform: 0,
  signature: '',
  booked_service_moving_house: false,
  booked_service_moving_office: false,
  booked_service_install_tv: false,
  booked_service_install_bed: false,
  booked_service_packaging: false,
  booked_service_clearance: false,
  provide_package_material: false,
  provide_package_material_paperbox: 0,
  provide_package_material_bubblepaper: 0,
  provide_package_material_tape: 0,
  moving_amount: 0,
  inout_warehouse_amount: 0,
  deposit_amount: 0,
  provide_box_date: null,
  payment_date: null,
  moving_items: [],
  extra_service_packaging: false,
  extra_service_packaging_amount: 0,
  extra_service_clearance: false,
  extra_service_clearance_amount: 0,
  extra_service_install_tv: false,
  extra_service_install_tv_amount: 0,
  extra_service_install_bed: false,
  extra_service_install_bed_amount: 0,
  remarks: '',
};
const SAMPLE_QUOTATION = {
  company_name: '潮流精靈有限公司',
  customer_name: '陳小文',
  contact_phone: '20001234',
  title: 2,
  moving_date: new Date('2024-08-10'),
  moving_time: new Date('2024-08-10 12:30'),
  old_address: '九龍灣偉業街業安工廠大廈2座50樓04室',
  old_address_stairs: 0,
  old_address_level_type: 1,
  old_address_platform: 0,
  new_address: '柴灣豐業街6號志榮昌工業大廈40樓12室',
  new_address_stairs: 1,
  new_address_level_type: 2,
  new_address_platform: 1.5,
  signature: '',
  booked_service_moving_house: false,
  booked_service_moving_office: true,
  booked_service_install_tv: false,
  booked_service_install_bed: true,
  booked_service_packaging: true,
  booked_service_clearance: false,
  provide_package_material: true,
  provide_package_material_paperbox: 20,
  provide_package_material_bubblepaper: 10,
  provide_package_material_tape: 30,
  moving_amount: 35000,
  inout_warehouse_amount: 0,
  deposit_amount: 10000,
  provide_box_date: new Date('2024-08-01'),
  payment_date: new Date ('2024-07-25'),
  moving_items: [{"id":2,"qty":1},{"id":19,"qty":2},{"id":23,"qty":5},{"id":24,"qty":5},{"id":20,"qty":1},{"id":17,"qty":3},{"id":22,"qty":1},{"id":25,"qty":2},{"id":33,"qty":1},{"id":37,"qty":1},{"id":38,"qty":1},{"id":39,"qty":1},{"id":35,"qty":5},{"id":40,"qty":0},{"id":36,"qty":1},{"id":42,"qty":1},{"id":49,"qty":1},{"id":53,"qty":1},{"id":57,"qty":1},{"id":58,"qty":2},{"id":54,"qty":1},{"id":55,"qty":5},{"id":64,"qty":1},{"id":77,"qty":2},{"id":50,"qty":2}],
  extra_service_packaging: false,
  extra_service_packaging_amount: 0,
  extra_service_clearance: true,
  extra_service_clearance_amount: 500,
  extra_service_install_tv: false,
  extra_service_install_tv_amount: 0,
  extra_service_install_bed: false,
  extra_service_install_bed_amount: 0,
  remarks: '所有需搬運的物品已經提前標記，請按照標記和清單進行搬運，避免遺漏或混淆。\n搬運過程中，請注意文件的保密性，避免遺失或洩漏重要文件。\n對於需要拆裝的大型家具，請按照拆裝指引進行操作，確保搬運過程中不會損壞。',
};

const Orderform = (props) => {
  const toast = useRef(null);
  const signaturePadRef = useRef(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showMovingItemsOnly, setShowMovingItemsOnly] = useState(false);
  const [quotation, setQuotation] = useState(BLANK_QUOTATION);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    props.setPageParams({
      alias: 'orderform',
      title: '新增報價'
    });
  }, []);

  const updateQuotation = (field, value) => {
    // force set value from null to 1
    if (['title', 'old_address_level_type', 'new_address_level_type'].includes(field)) {
      if (value === null) {
        value = 1;
      }
    }

    setQuotation(quotation => {
      return {
        ...quotation,
        [field]: value,
      };
    })
  };

  const updateQuotationMovingItems = (itemId, qty) => {
    var updatedItems = [];
    if (quotation.moving_items.filter(item => item.id === itemId).length === 0) {
      // item is not exsting, add it with qty
      updatedItems = quotation.moving_items;
      updatedItems.push({
        id: itemId,
        qty: qty,
      });
    } else {
      // item is exsting, update its qty
      updatedItems = quotation.moving_items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            qty: qty,
          };
        } else {
          return item;
        }
      });
    }
    updateQuotation('moving_items', updatedItems);
  };
  
  const preSubmit = () => {
    // handle numeric values
    setQuotation({
      ...quotation,
      old_address_stairs: (quotation.old_address_stairs === '') ? 0 : quotation.old_address_stairs,
      old_address_platform: (quotation.old_address_platform === '') ? 0 : quotation.old_address_platform,
      new_address_stairs: (quotation.new_address_stairs === '') ? 0 : quotation.new_address_stairs,
      new_address_platform: (quotation.new_address_platform === '') ? 0 : quotation.new_address_platform,
      provide_package_material_paperbox: (quotation.provide_package_material_paperbox === '') ? 0 : quotation.provide_package_material_paperbox,
      provide_package_material_bubblepaper: (quotation.provide_package_material_bubblepaper === '') ? 0 : quotation.provide_package_material_bubblepaper,
      provide_package_material_tape: (quotation.provide_package_material_tape === '') ? 0 : quotation.provide_package_material_tape,
      moving_amount: (quotation.moving_amount === '') ? 0 : quotation.moving_amount,
      inout_warehouse_amount: (quotation.inout_warehouse_amount === '') ? 0 : quotation.inout_warehouse_amount,
      deposit_amount: (quotation.deposit_amount === '') ? 0 : quotation.deposit_amount,
      extra_service_packaging_amount: (quotation.extra_service_packaging_amount === '') ? 0 : quotation.extra_service_packaging_amount,
      extra_service_clearance_amount: (quotation.extra_service_clearance_amount === '') ? 0 : quotation.extra_service_clearance_amount,
      extra_service_install_tv_amount: (quotation.extra_service_install_tv_amount === '') ? 0 : quotation.extra_service_install_tv_amount,
      extra_service_install_bed_amount: (quotation.extra_service_install_bed_amount === '') ? 0 : quotation.extra_service_install_bed_amount,
    });
    setIsShowConfirmDialog(true);
  };

  const submit = () => {
    confirmDialog({
      message: signaturePadRef.current.isEmpty() ?
        <div dangerouslySetInnerHTML={{ __html: `確認以上資料無誤並提交報價？\n\n（客戶未簽署）`.replace(/\n/g, '<br/>') }} /> :
        '確認以上資料無誤並提交報價？',
      header: '確定提交',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-info',
      accept: () => doSubmit(),
    });

    const doSubmit = async () => {
      var quotationToBeSubmitted = {
        ...quotation,
        id: '',
        booked_service_clearance: quotation.booked_service_clearance ? 1 : 0,
        booked_service_install_bed: quotation.booked_service_install_bed ? 1 : 0,
        booked_service_install_tv: quotation.booked_service_install_tv ? 1 : 0,
        booked_service_moving_house: quotation.booked_service_moving_house ? 1 : 0,
        booked_service_moving_office: quotation.booked_service_moving_office ? 1 : 0,
        booked_service_packaging: quotation.booked_service_packaging ? 1 : 0,
        extra_service_clearance: quotation.extra_service_clearance ? 1 : 0,
        extra_service_install_bed: quotation.extra_service_install_bed ? 1 : 0,
        extra_service_install_tv: quotation.extra_service_install_tv ? 1 : 0,
        extra_service_packaging: quotation.extra_service_packaging ? 1 : 0,
        provide_package_material: quotation.provide_package_material ? 1 : 0,
        moving_items: JSON.stringify(quotation.moving_items),
      };
      // handle date time values
      quotationToBeSubmitted.moving_date = (quotationToBeSubmitted.moving_date === null) ? '' : functions.dateToString(quotationToBeSubmitted.moving_date);
      quotationToBeSubmitted.moving_time = (quotationToBeSubmitted.moving_time === null) ? '' : functions.dateToTimeString(quotationToBeSubmitted.moving_time);
      quotationToBeSubmitted.payment_date = (quotationToBeSubmitted.payment_date === null) ? '' : functions.dateToString(quotationToBeSubmitted.payment_date);
      quotationToBeSubmitted.provide_box_date = (quotationToBeSubmitted.provide_box_date === null) ? '' : functions.dateToString(quotationToBeSubmitted.provide_box_date);

      if (signaturePadRef.current.isEmpty()) {
        quotationToBeSubmitted.signature = '';
      } else {
        quotationToBeSubmitted.signature = signaturePadRef.current.toDataURL();
      }
      // start fubmission
      setIsSaving(true);
      const response = await fetchApi('api/v1/quotation/saveQuotation', quotationToBeSubmitted);
      if (response.state === 1) {
        toast.current.show({ severity: 'success', summary: '報價單', detail: '已成功祈增報價單。' });
        setIsSaving(false);
        setActiveTabIndex(0);
        setQuotation(BLANK_QUOTATION);
        setIsShowConfirmDialog(false);
      }
    };
  };

  const resetOrderForm = () => {
    confirmDialog({
      message: '確認清除以上資料並重新填寫報價單？',
      header: '確定清除',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-warning',
      accept: () => doResetOrderForm(),
    });

    const doResetOrderForm = () => {
      setQuotation(BLANK_QUOTATION);
    };
  };

  return (
    <>
      <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
        <TabPanel header="客戶資料">
          <div className="grid">
            <div className="col-12 flex flex-column gap-2">
              <label>公司名稱</label>
              <InputText value={quotation.company_name} onChange={(e) => {updateQuotation('company_name', e.target.value)}} />
            </div>
          </div>
          <div className="grid">
            <div className="col-5 flex flex-column gap-2">
              <label>客戶姓名</label>
              <InputText value={quotation.customer_name} onChange={(e) => {updateQuotation('customer_name', e.target.value)}} />
            </div>
            <div className="col-3 flex flex-column gap-2">
              <label>聯絡電話</label>
              <InputText value={quotation.contact_phone} onChange={(e) => {updateQuotation('contact_phone', e.target.value)}} type="tel" />
            </div>
            <div className="col-4 flex flex-column gap-2">
              <label>&nbsp;</label>
              <SelectButton value={quotation.title} onChange={(e) => {updateQuotation('title', e.value)}} optionLabel="name" options={TITLES} />
            </div>
          </div>
          <div className="grid">
            <div className="col-4 flex flex-column gap-2">
              <label>搬運日期</label>
              <Calendar value={quotation.moving_date} onChange={(e) => {updateQuotation('moving_date', e.value)}} dateFormat="yy-mm-dd" placeholder="年-月-日" />
            </div>
            <div className="col-3 flex flex-column gap-2">
              <label>搬運時間</label>
              <Calendar value={quotation.moving_time} onChange={(e) => updateQuotation('moving_time', e.value)} timeOnly />
            </div>
          </div>
          <Panel header="舊地址" className="mt-3">
            <div className="grid">
              <div className="col-12 flex flex-column gap-2">
                <label>舊地址</label>
                <InputText value={quotation.old_address} onChange={(e) => {updateQuotation('old_address', e.target.value)}} />
              </div>
            </div>
            <div className="grid">
              <div className="col-2 flex flex-column gap-2">
                <label>樓梯</label>
                <InputText value={quotation.old_address_stairs} onChange={(e) => {updateQuotation('old_address_stairs', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" />
              </div>
              <div className="col-3 flex flex-column gap-2">
                <label>&nbsp;</label>
                <SelectButton value={quotation.old_address_level_type} onChange={(e) => {updateQuotation('old_address_level_type', e.value)}} optionLabel="name" options={LEVEL_TYPES} />
              </div>
              <div className="col-2 flex flex-column gap-2">
                <label>平台（米）</label>
                <InputText value={quotation.old_address_platform} onChange={(e) => {updateQuotation('old_address_platform', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" />
              </div>
            </div>
          </Panel>
          <Panel header="新地址" className="mt-3">
            <div className="grid">
              <div className="col-12 flex flex-column gap-2">
                <label>新地址</label>
                <InputText value={quotation.new_address} onChange={(e) => {updateQuotation('new_address', e.target.value)}} />
              </div>
            </div>
            <div className="grid">
              <div className="col-2 flex flex-column gap-2">
                <label>樓梯</label>
                <InputText value={quotation.new_address_stairs} onChange={(e) => {updateQuotation('new_address_stairs', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" />
              </div>
              <div className="col-3 flex flex-column gap-2">
                <label>&nbsp;</label>
                <SelectButton value={quotation.new_address_level_type} onChange={(e) => {updateQuotation('new_address_level_type', e.value)}} optionLabel="name" options={LEVEL_TYPES} />
              </div>
              <div className="col-2 flex flex-column gap-2">
                <label>平台（米）</label>
                <InputText value={quotation.new_address_platform} onChange={(e) => {updateQuotation('new_address_platform', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" />
              </div>
            </div>
          </Panel>
          {process.env.NODE_ENV === 'development' && <div className="w-full h-12rem" onClick={() => {setQuotation(SAMPLE_QUOTATION)}} />}
        </TabPanel>
        <TabPanel header="搬運詳情">
          <div className="grid">
            <div className="col-12 flex flex-column gap-2">
              <p className="m-0">項目 - 與客人確認及包括在此報價內之服務</p>
            </div>
          </div>
          <div className="grid mt-2 align-items-center">
            <div className="col-6 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="booked_service_moving_house" name="booked_service_moving_house" onChange={(e) => {updateQuotation('booked_service_moving_house', e.checked)}} checked={quotation.booked_service_moving_house} />
                <label htmlFor="booked_service_moving_house" className="ml-2">搬屋 / 搬傢俬 / 搬雜物</label>
              </div>
            </div>
            <div className="col-6 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="booked_service_moving_office" name="booked_service_moving_office" onChange={(e) => {updateQuotation('booked_service_moving_office', e.checked)}} checked={quotation.booked_service_moving_office} />
                <label htmlFor="booked_service_moving_office" className="ml-2">搬辦公室</label>
              </div>
            </div>
          </div>
          <div className="grid mt-2 align-items-center">
            <div className="col-6 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="booked_service_install_tv" name="booked_service_install_tv" onChange={(e) => {updateQuotation('booked_service_install_tv', e.checked)}} checked={quotation.booked_service_install_tv} />
                <label htmlFor="booked_service_install_tv" className="ml-2">拆 / 裝 電視</label>
              </div>
            </div>
            <div className="col-6 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="booked_service_install_bed" name="booked_service_install_bed" onChange={(e) => {updateQuotation('booked_service_install_bed', e.checked)}} checked={quotation.booked_service_install_bed} />
                <label htmlFor="booked_service_install_bed" className="ml-2">拆 / 裝 床 / 櫃</label>
              </div>
            </div>
          </div>
          <div className="grid mt-2 align-items-center">
            <div className="col-6 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="booked_service_packaging" name="booked_service_packaging" onChange={(e) => {updateQuotation('booked_service_packaging', e.checked)}} checked={quotation.booked_service_packaging} />
                <label htmlFor="booked_service_packaging" className="ml-2">包裝入箱 / 拆包裝</label>
              </div>
            </div>
            <div className="col-6 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="booked_service_clearance" name="booked_service_clearance" onChange={(e) => {updateQuotation('booked_service_clearance', e.checked)}} checked={quotation.booked_service_clearance} />
                <label htmlFor="booked_service_clearance" className="ml-2">清場垃圾</label>
              </div>
            </div>
          </div>
          <div className="grid mt-2 align-items-center">
            <div className="col-3 flex flex-column gap-2">
              <label className="w-full">&nbsp;</label>
              <div className="flex">
                <Checkbox inputId="provide_package_material" name="provide_package_material" onChange={(e) => {updateQuotation('provide_package_material', e.checked)}} checked={quotation.provide_package_material} />
                <label htmlFor="provide_package_material" className="ml-2">供應包裝物料</label>
              </div>
            </div>
            <div className="col-2 flex flex-column gap-2">
              <label className="w-full">紙箱</label>
              <InputText value={quotation.provide_package_material_paperbox} onChange={(e) => {updateQuotation('provide_package_material_paperbox', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" className="w-full" />
            </div>
            <div className="col-2 flex flex-column gap-2">
              <label className="w-full">泡泡紙</label>
              <InputText value={quotation.provide_package_material_bubblepaper} onChange={(e) => {updateQuotation('provide_package_material_bubblepaper', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" className="w-full" />
            </div>
            <div className="col-2 flex flex-column gap-2">
              <label className="w-full">膠紙</label>
              <InputText value={quotation.provide_package_material_tape} onChange={(e) => {updateQuotation('provide_package_material_tape', e.target.value)}} onFocus={(e) => {e.target.select()}} type="number" className="w-full" />
            </div>
          </div>
          <div className="grid mt-2">
            <div className="col-3 flex flex-column gap-2">
              <label>搬運金額</label>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.moving_amount} onChange={(e) => {updateQuotation('moving_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
            <div className="col-3 flex flex-column gap-2">
              <label>入倉出倉運費</label>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.inout_warehouse_amount} onChange={(e) => {updateQuotation('inout_warehouse_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
            <div className="col-3 flex flex-column gap-2">
              <label>訂金</label>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.deposit_amount} onChange={(e) => {updateQuotation('deposit_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
          </div>
          <div className="grid mt-2">
            <div className="col-3 flex flex-column gap-2">
              <label>送箱日期</label>
              <Calendar value={quotation.provide_box_date} onChange={(e) => {updateQuotation('provide_box_date', e.value)}} dateFormat="yy-mm-dd" placeholder="年-月-日" />
            </div>
            <div className="col-3 flex flex-column gap-2">
              <label>繳款日期</label>
              <Calendar value={quotation.payment_date} onChange={(e) => {updateQuotation('payment_date', e.value)}} dateFormat="yy-mm-dd" placeholder="年-月-日" />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="搬運物品">
          <div className="grid">
            <div className="col-12 flex flex-row justify-content-between gap-2">
              <p className="m-0">搬運物品清單</p>
              <div className="flex">
                <Checkbox inputId="moving_item_only" name="moving_item_only" onChange={(e) => {setShowMovingItemsOnly(e.checked)}} checked={showMovingItemsOnly} />
                <label htmlFor="moving_item_only" className="ml-2">只顯示需要搬運的物品</label>
              </div>
            </div>
          </div>
          <div className="grid mt-2 align-items-center flex-row">
              {MOVING_ITEMS.map((item) => {
                let qty = 0;
                let targetItem = quotation.moving_items.filter(targetItem => targetItem.id === item.id);
                if (targetItem.length > 0) {
                  qty = targetItem[0].qty;
                }
                return (
                  <div className="col-3 gap-2" key={item.id}>
                    <ItemNumberInput
                      itemName={item.name}
                      value={qty}
                      onChange={(value) => {updateQuotationMovingItems(item.id, value)}}
                      visible={!showMovingItemsOnly || qty > 0}
                    />
                  </div>
                );
              })}
          </div>       
        </TabPanel>
        <TabPanel header="其他服務及額外收費">
          <div className="grid">
            <div className="col-12 flex flex-column gap-2">
              <p className="m-0">額外收費 - 指臨時或到現場才決定進行的而不包括在以上報價內的服務收費</p>
            </div>
          </div>
          <div className="grid mt-2 align-items-center">
            <div className="col-3 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="extra_service_packaging" name="extra_service_packaging" onChange={(e) => {updateQuotation('extra_service_packaging', e.checked)}} checked={quotation.extra_service_packaging} />
                <label htmlFor="extra_service_packaging" className="ml-2">包裝 入箱</label>
              </div>
            </div>
            <div className="col-3 flex flex-column gap-2">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.extra_service_packaging_amount} onChange={(e) => {updateQuotation('extra_service_packaging_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col-3 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="extra_service_clearance" name="extra_service_clearance" onChange={(e) => {updateQuotation('extra_service_clearance', e.checked)}} checked={quotation.extra_service_clearance} />
                <label htmlFor="extra_service_clearance" className="ml-2">清場掉傢俬垃圾</label>
              </div>
            </div>
            <div className="col-3 flex flex-column gap-2">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.extra_service_clearance_amount} onChange={(e) => {updateQuotation('extra_service_clearance_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col-3 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="extra_service_install_tv" name="extra_service_install_tv" onChange={(e) => {updateQuotation('extra_service_install_tv', e.checked)}} checked={quotation.extra_service_install_tv} />
                <label htmlFor="extra_service_install_tv" className="ml-2">拆 / 裝 電視</label>
              </div>
            </div>
            <div className="col-3 flex flex-column gap-2">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.extra_service_install_tv_amount} onChange={(e) => {updateQuotation('extra_service_install_tv_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col-3 flex flex-column gap-2">
              <div className="flex">
                <Checkbox inputId="extra_service_install_bed" name="extra_service_install_bed" onChange={(e) => {updateQuotation('extra_service_install_bed', e.checked)}} checked={quotation.extra_service_install_bed} />
                <label htmlFor="extra_service_install_bed" className="ml-2">拆 / 裝 床 / 櫃</label>
              </div>
            </div>
            <div className="col-3 flex flex-column gap-2">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">$</span>
                <InputNumber value={quotation.extra_service_install_bed_amount} onChange={(e) => {updateQuotation('extra_service_install_bed_amount', e.value)}} onFocus={(e) => {e.target.select()}} inputClassName="text-right" />
              </div>
            </div>
          </div>
          <div className="grid mt-4 align-items-center">
            <div className="col-12 flex flex-column gap-2">
              <label className="w-full">其他 / 備註</label>
              <InputTextarea autoResize value={quotation.remarks} onChange={(e) => {updateQuotation('remarks', e.target.value)}} rows={5} className="w-full" />
            </div>
          </div>
          <div className="grid mt-4 align-items-center">
            <div className="col-12 flex flex-column gap-2">
              <Button label="提交報價" icon="pi pi-send" onClick={preSubmit} size="large" />
            </div>
          </div>
          <div className="text-center mt-4">
            <Link onClick={resetOrderForm} className="inline-block">清除所有資料並重新輸入</Link>
          </div>
        </TabPanel>
      </TabView>

      <Dialog header="確認報價內容" visible={isShowConfirmDialog} className="detail-dialog" onHide={() => {if (!isSaving) {setIsShowConfirmDialog(false)} }}>
        <Panel header="客戶資料">
          <div className="mb-3">
            <div className="text-lg">公司名稱</div>
            <div className="text-2xl">{quotation.company_name || '-'}</div>
          </div>
          <div className="mb-3">
            <div className="text-sm">客戶姓名</div>
            <div className="text-2xl">{quotation.customer_name || '-'} {quotation.customer_name===''?'':'（'+TITLES.filter(title => title.value === quotation.title)[0]?.name+'）'}</div>
          </div>
          <div className="mb-3">
            <div className="text-lg">聯絡電話</div>
            <div className="text-2xl">{quotation.contact_phone || '-'}</div>
          </div>
          <div className="grid mb-3">
            <div className="col-4">
              <div className="text-lg">搬運日期</div>
              <div className="text-2xl">{quotation.moving_date === null ? '-' : functions.dateToChinese(quotation.moving_date)}</div>
            </div>
            <div className="col-4">
              <div className="text-lg">搬運時間</div>
              <div className="text-2xl">{quotation.moving_time === null ? '-' : functions.dateToTimeChinese(quotation.moving_time)}</div>
            </div>
          </div>
          <Divider align="center">
            <span className="p-tag text-lg font-normal">舊地址</span>
          </Divider>
          <div className="mb-3">
            <div className="text-lg">舊地址</div>
            <div className="text-2xl">{quotation.old_address || '-'}</div>
          </div>
          <div className="mb-3">
            <div className="text-lg">樓梯</div>
            <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.old_address_stairs)} {quotation.old_address_stairs===0?'':'（'+LEVEL_TYPES.filter(type => type.value === quotation.old_address_level_type)[0]?.name+'）'}</div>
          </div>
          <div className="mb-3">
            <div className="text-lg">平台（米）</div>
            <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.old_address_platform)}</div>
          </div>
          <Divider align="center">
            <span className="p-tag text-lg font-normal">新地址</span>
          </Divider>
          <div className="mb-3">
            <div className="text-lg">新地址</div>
            <div className="text-2xl">{quotation.new_address || '-'}</div>
          </div>
          <div className="mb-3">
            <div className="text-lg">樓梯</div>
            <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.new_address_stairs)} {quotation.new_address_stairs===0?'':'（'+LEVEL_TYPES.filter(type => type.value === quotation.new_address_level_type)[0]?.name+'）'}</div>
          </div>
          <div className="mb-3">
            <div className="text-lg">平台（米）</div>
            <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.new_address_platform)}</div>
          </div>
        </Panel>
        <Panel header="搬運詳情" className="mt-4">
          <div className="mb-3">
            <div>項目 - 與客人確認及包括在此報價內之服務</div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.booked_service_moving_house} />
              <label className="ml-2 text-lg">搬屋 / 搬傢俬 / 搬雜物</label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.booked_service_moving_office} />
              <label className="ml-2 text-lg">搬辦公室</label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.booked_service_install_tv} />
              <label className="ml-2 text-lg">拆 / 裝 電視</label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.booked_service_install_bed} />
              <label className="ml-2 text-lg">拆 / 裝 床 / 櫃</label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.booked_service_packaging} />
              <label className="ml-2 text-lg">包裝入箱 / 拆包裝</label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.booked_service_clearance} />
              <label className="ml-2 text-lg">清場垃圾</label>
            </div>
          </div>
          <div className="flex flex-row align-items-center mb-3">
            <div className="flex">
              <Checkbox checked={quotation.provide_package_material} />
              <label className="ml-2 text-lg">供應包裝物料</label>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-4">
              <div className="text-lg">紙箱</div>
              <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.provide_package_material_paperbox)}</div>
            </div>
            <div className="col-4">
              <div className="text-lg">泡泡紙</div>
              <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.provide_package_material_bubblepaper)}</div>
            </div>
            <div className="col-4">
              <div className="text-lg">膠紙</div>
              <div className="text-2xl">{functions.showEmptyZeroValueOrHyphen(quotation.provide_package_material_tape) || '-'}</div>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-4">
              <div className="text-lg">搬運金額</div>
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.moving_amount)}</div>
            </div>
            <div className="col-4">
              <div className="text-lg">入倉出倉運費</div>
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.inout_warehouse_amount)}</div>
            </div>
            <div className="col-4">
              <div className="text-lg">訂金</div>
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.deposit_amount)}</div>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-4">
              <div className="text-lg">送箱日期</div>
              <div className="text-2xl">{quotation.provide_box_date === null ? '-' : functions.dateToChinese(quotation.provide_box_date)}</div>
            </div>
            <div className="col-4">
              <div className="text-lg">繳款日期</div>
              <div className="text-2xl">{quotation.payment_date === null ? '-' : functions.dateToChinese(quotation.payment_date)}</div>
            </div>
          </div>
        </Panel>
        <Panel header="搬運物品" className="mt-4">
          <div className="grid mb-3">
            {quotation.moving_items.map((item, index) => {
              let detail = MOVING_ITEMS.filter(i => i.id === item.id)[0];
              return (
                <div className="col-3" key={index}>
                  <div className="text-lg">{detail.name}</div>
                  <div className="text-2xl">{item.qty}</div>
                </div>
              );
            })}
          </div>
        </Panel>
        <Panel header="其他服務及額外收費" className="mt-4">
          <div className="grid mb-3">
            <div className="col-4">
              <div className="flex">
                <Checkbox checked={quotation.extra_service_packaging} />
                <label className="ml-2 text-lg">包裝 入箱</label>
              </div>
            </div>
            <div className="col-8">
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.extra_service_packaging_amount)}</div>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-4">
              <div className="flex">
                <Checkbox checked={quotation.extra_service_clearance} />
                <label className="ml-2 text-lg">清場掉傢俬垃圾</label>
              </div>
            </div>
            <div className="col-8">
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.extra_service_clearance_amount)}</div>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-4">
              <div className="flex">
                <Checkbox checked={quotation.extra_service_install_tv} />
                <label className="ml-2 text-lg">拆 / 裝 電視</label>
              </div>
            </div>
            <div className="col-8">
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.extra_service_install_tv_amount)}</div>
            </div>
          </div>
          <div className="grid mb-5">
            <div className="col-4">
              <div className="flex">
                <Checkbox checked={quotation.extra_service_install_bed} />
                <label className="ml-2 text-lg">拆 / 裝 床 / 櫃</label>
              </div>
            </div>
            <div className="col-8">
              <div className="text-2xl">HK${functions.showEmptyZeroValueOrHyphen(quotation.extra_service_install_bed_amount)}</div>
            </div>
          </div>
          <div className="">
            <div className="text-lg mb-3">其他 / 備註</div>
            <div className="text-2xl">{quotation.remarks === '' ? '-' : <div dangerouslySetInnerHTML={{ __html: quotation.remarks.replace(/\n/g, '<br/>') }} />}</div>
          </div>
        </Panel>
        <Panel header="客戶確認簽名" className="mt-4">
          <div className="grid">
            <div className="col-10">
              <div className="signature-pad-container">
                <SignaturePad ref={signaturePadRef} options={{minWidth: 1, maxWidth: 4, penColor: '#146c9c'}} disabled />
                {isSaving && <div className="signature-pad-cover" />}
              </div>
              <div className="flex flex-row mt-2 justify-content-start">
                <Button label="清除" icon="pi pi-times" size="small" severity="warning" onClick={() => {signaturePadRef.current.clear()}} disabled={isSaving} />
              </div>
            </div>
          </div>
        </Panel>
        <div className="grid mt-4 align-items-center">
          <div className="col-12 flex flex-column gap-2">
            {isSaving ?
              <Button icon="pi pi-spin pi-spinner" className="w-full" disabled size="large" />
            :
              <Button label="確認以上資料無誤並提交報價" icon="pi pi-send" onClick={submit} size="large" />
            }
          </div>
        </div>
      </Dialog>
      <ConfirmDialog />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Orderform;
  