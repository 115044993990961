import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Outlet } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';

import SidebarContainer from 'components/SidebarContainer';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const LayoutDashboard = (props) => {
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    // check if user's token is still valid by calling getMe()
    const checkToken = async () => {
      const response = await fetchApi('api/v1/user/getMe');
      if (response.http_status === 403) { // logout when 403 is returned
        functions.setCookie('app-user-id', '');
        functions.setCookie('app-user-token', '');
        localStorage.clear();
        navigate('/');
      }
    }
    checkToken();
  }, []);

  useEffect(() => {
    functions.setBodyPage(props.pageParams?.alias);
  }, [props]);

  const customHeader = (
    <div className="flex align-items-center gap-2">
        <Image src="images/likkan-icon.jpg" alt="Image" width="30" />
        <span className="font-bold">力勤物流搬運</span>
    </div>
  );

  return (
    <>
      <div className="flex flex-column">
        <div className="topbar flex flex-row justify-content-between align-items-center gap-2 p-3">
          <div className="topbar-left flex flex-row align-items-center">
            <Button icon="pi pi-bars" size="small" onClick={() => {setSidebarVisible(true)}} />
            <span className="ml-3">{props.pageParams?.title}</span>
          </div>
          <div className="topbar-right">
            <Image src="images/likkan-icon.jpg" alt="Image" width="40" />
          </div>
        </div>
        <div className="main-content p-4">
          <Outlet />
        </div>
      </div>
      <Sidebar
        className="sidebar-navigation"
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        header={customHeader}
      >
        <SidebarContainer currentPageAlias={props.pageParams?.alias} />
      </Sidebar>
    </>
  )
};

export default LayoutDashboard;
