import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const MessageBox = (props) => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(''); // info, warning, error
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [titleColor, setTitleColor] = useState('');
  const [okText, setOkText] = useState('');

  useEffect(() => {
    setVisible(props.visible);
    setType(props.type);
    switch (props.type) {
      default: setTitleColor('text-cyan-500'); break;
      case 'info': setTitleColor('text-cyan-500'); break;
      case 'warning': setTitleColor('text-orange-500'); break;
      case 'error': setTitleColor('text-red-500'); break;
    }
    setTitle(props.title);
    setContent(props.content);
    setOkText(props.okText || '確定');
  }, [props]);

  const onHide = () => {
    setVisible(false);
    if (props.onHide) {
      props.onHide();
    }
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className={`pi pi-exclamation-triangle text-2xl mr-1 ${titleColor} font-semibold`}></span>
      <span className={`font-bold white-space-nowrap ${titleColor}`}>{title}</span>
    </div>
  );
  const footerContent = (
    <div>
      <Button label={okText} icon="pi pi-check" onClick={onHide} autoFocus />
    </div>
  );

  return (
    <Dialog visible={visible} modal header={headerElement} footer={footerContent} style={{ width: '50vw' }} onHide={onHide}>
      <div>{content}</div>
    </Dialog>
  );
};

export default MessageBox;