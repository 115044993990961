import { useNavigate } from 'react-router-dom';

import functions from 'lib/functions';

const domain = (process.env.NODE_ENV === 'development') ?
  'http://localhost:12345/' :
  'https://summer-cherry-0a86.tectom-limited.workers.dev/'

const fetchApi = async (url, body={}, method='POST', headers={}) => {
  const appUserId = functions.getCookie('app-user-id') || '';
  const appUserToken = functions.getCookie('app-user-token') || '';
  // console.log('🟢 =======================');
  // console.log('🟢 App-User-Id:', appUserId);
  // console.log('🟢 App-User-Token:', appUserToken);
  // console.log('🟢 =======================');

  return await fetch(url.substring(0,8)==='https://'||url.substring(0,7)==='http://' ? url : domain+url, {
    method: method,
    body: JSON.stringify({
      ...body,
    }),
    headers: new Headers({
      ...headers,
      'Content-Type': 'application/json',
      'App-User-Id': appUserId,
      'App-User-Token': appUserToken,
    }),
  }).then((response) => {
    return response.json();
  }).then((responseData) => {
    return responseData;
  }).catch((error) => {
    return {
      data: [],
      http_status: 403
    }
  });
};

export default fetchApi;
