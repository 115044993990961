import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Chip } from 'primereact/chip';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const SidebarContainer = (props) => {
  const navigate = useNavigate();
  const [me, setMe] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  // hardcode menu items here
  let items = [
    { label: '儀表板', icon: 'pi pi-home', alias: 'dashboard', url: '/dashboard', },
    { label: '報價記錄', icon: 'pi pi-folder', alias: 'quotation', url: '/quotation', permission: 'quotation' },
    { label: '新增報價', icon: 'pi pi-file-plus', alias: 'orderform', url: '/orderform', permission: 'orderform' },
    { label: '用戶管理', icon: 'pi pi-users', alias: 'user', url: '/user', permission: 'user' },
    { separator: true },
    { label: '登出', icon: 'pi pi-sign-out', command: () => {askLogout()} }
  ];

  useEffect(() => {
    setMe(JSON.parse(localStorage.getItem('user')));
  }, []);

  useEffect(() => {
    if (me !== null) {
    // filter items by user permission
    items = items.filter((item) => {
      return (item.permission === undefined || me.permissions.includes(item.permission));
    });
    // find current item and assign current class
    items = items.map((item) => {
      return {
        ...item,
        className: props.currentPageAlias === item.alias ? 'p-focus' : '',
      };
    });
    setMenuItems(items);
    }
  }, [me]);

  const logout = () => {
    const response = fetchApi('api/v1/user/logout');
    functions.setCookie('app-user-id', '');
    functions.setCookie('app-user-token', '');
    localStorage.clear();
    navigate('/');
  };

  const askLogout = () => {
    confirmDialog({
      message: '你確定要登出系統嗎？',
      header: '確定登出',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-info',
      accept: () => logout(),
    });
  };

  return (
    me === null ?
    <></> :
    <>
      <div className="flex flex-column h-full justify-content-between">
        <Menu model={menuItems} className="w-full border-none" />
        <div className="p-3 pb-0">
          <Chip label={me.nickname} icon="pi pi-user" className="w-full border-round-sm" />
        </div>
      </div>
      <ConfirmDialog />
    </>
  );
};

export default SidebarContainer;
