import React, { useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const Dashboard = (props) => {
  const [me, setMe] = useState(null);
  const [weather, setWeather] = useState(JSON.parse('{"generalSituation":"Disabled on development mode.","tcInfo":"","fireDangerWarning":"","forecastPeriod":"本港地區今晚及明日天氣預測","forecastDesc":"...","outlook":"..."}'));

  useEffect(() => {
    props.setPageParams({
      alias: 'dashboard',
      title: '儀表板'
    });
  }, []);
  
  useEffect(() => {
    getMe();
    getWeather();
  }, []);
  
  const getMe = async () => {
    const response = await fetchApi('api/v1/user/getMe');
    setMe(response.data.user);
  }
  
  const getWeather = async () => {
    try {
      const response = await fetch('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=tc');
      const json = await response.json();
      setWeather(json);
    } catch (e) {}
  };

  return (
    <>
      <div className="mb-4">{me?.nickname ? me.nickname+'，' : ''}你好👋 歡迎使用力勤物流搬運系統！</div>
      <div className="grid">
        <div className="col-6">
          <Panel header="🔔 公告">
            <div className="mb-2">近日天氣炎熱，勞工處多次發出工作暑熱警告，請各員工多加休息，注意健康。</div>
            <div className="mb-2">電子報價工具已經啟用！</div>
          </Panel>
        </div>
        <div className="col-6">
          {weather !== null &&
            <Panel header="🌈 天氣概況">
              <p className="m-0">{weather.generalSituation}</p>
            </Panel>
          }
        </div>
      </div>
    </>
  );
};

export default Dashboard;
