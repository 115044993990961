import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Quotation = (props) => {
  // const { id } = useParams();

  useEffect(() => {
    props.setPageParams({
      alias: 'quotation',
      title: '報價記錄'
    });
  }, []);

  return (
    <>
      <div>Coming soon...</div>
    </>
  );
};
  
export default Quotation;
  