import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

import MessageBox from 'components/MessageBox';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const Home = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxContent, setMessageBoxContent] = useState('');

  useEffect(() => {
    functions.setBodyPage('home');
  }, []);

  const login = async () => {
    setIsLoading(true);
    const response = await fetchApi('api/v1/user/login', {
      username: username,
      password: password,
    });
    if (response.state === 0) {
      // login fail
      if (response.error[0] === 'empty_username_password') { setMessageBoxContent('必須輸入用戶名稱及密碼。'); }
      if (response.error[0] === 'incorrect_username_password') { setMessageBoxContent('登用戶名稱或密碼錯誤。'); }
      setShowMessageBox(true);
    } else if (response.state === 1) {
      // login success
      if (response.data.user_session) {
        functions.setCookie('app-user-id', response.data.user_session.user_id);
        functions.setCookie('app-user-token', response.data.user_session.user_token);
        // save user data into localstorage
        localStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/dashboard');
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* <div class="bg"></div>
      <div class="bg bg2"></div>
      <div class="bg bg3"></div> */}
      <div className="flex flex-column h-full justify-content-center align-items-center">
        <Card className="w-6">
          <div className="flex flex-column align-items-center">
            <Image src="images/likkan-icon.jpg" alt="Image" width="100" />
            <Divider align="center">
              <span className="">登入系統</span>
            </Divider>
            <div className="flex flex-column gap-1 mt-2">
              <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value.toLowerCase())} placeholder="登入名稱" />
              <Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={false}  placeholder="密碼" />
              <Button label="登入" icon="pi pi-sign-in" loading={isLoading} onClick={login} disabled={isLoading} />
            </div>
          </div>
        </Card>
        <MessageBox visible={showMessageBox} title="登入錯誤" content={messageBoxContent} type="warning" onHide={() => {setShowMessageBox(false)}} />
      </div>
    </>
  );
};
  
export default Home;
